import React, {useContext, useState} from 'react'
import {MyContext} from './MyContext';

import LogoLogin from '../../components/assets/login/logo.gif';

import '../../App.css';


function Login(){

    const {toggleNav,loginUser,isLoggedIn} = useContext(MyContext);

    const initialState = {
        userInfo:{
            email:'',
            clave:'',
        },
        errorMsg:'',
        successMsg:'',
    }

    const [state,setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);
        if(data.success && data.token){
            setState({
                ...initialState,
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else{
            setState({
                ...state,
                successMsg:'',
                errorMsg:data.message
            });
        }
    }

    // Show Message on Error or Success
    let successMsg = '';
    let errorMsg = '';
    if(state.errorMsg){
        errorMsg = <div className="error-msg">{state.errorMsg}</div>;
    }
    if(state.successMsg){
        successMsg = <div className="success-msg">{state.successMsg}</div>;
    }



    return(

        <div className="login-4l">
            <div className="containerl-fluid">
                <div className="rowl login-boxl">
                    <div className="col-lg-6 form-sectionl">
                        <div class="form-innerl">
                            
                                <h1 style={{color:"white"}}>ODÍN INTERFACE</h1>
                            
                            <h3>
                                Inteligencia Artificial
                            </h3>
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                <strong>Hola diego,</strong> En que te puedo ayudar!
                                 
                                {errorMsg}
                                {successMsg}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            <form onSubmit={submitForm} noValidate>
                                <div class="form-group position-relative clearfix">
                                    <input name="email" type="email" className="form-control"
                                     placeholder="Usuario" aria-label="Email Address"  
                                     value={state.userInfo.email} onChange={onChangeValue} autoComplete="off" />

                                    <div class="login-popover login-popover-abs" data-bs-toggle="popover" data-bs-trigger="hover" title="Solo Usuarios Creados" data-bs-content="Usuarios creados por ODÍN INTERFACE">
                                        <i class="fa fa-info-circle"></i>
                                    </div>
                                </div>
                                <div class="form-group clearfix position-relative password-wrapper">
                                    <input name="clave" type="password" className="form-control"
                                     autocomplete="off" placeholder="Contraseña" aria-label="Password"
                                     value={state.userInfo.clave} onChange={onChangeValue} />
                                    <i class="fa fa-eye password-indicator" ></i>
                                </div>
                                <div class="form-group mb-0 clearfix">
                                    <button type="submit" class="btn btn-lg btn-primary btn-theme">Ingresar</button>
                                    <a href="#" class="forgot-password float-end">Recuperar clave</a>


                                   
                                    
                                </div>
                                <div class="extra-login clearfix">
                                    <span>Todos los derechos reservados - Giovanny Chavez Marin</span>
                                </div>
                                <div class="clearfix"></div>
                                
                            </form>
                            <div class="clearfix"></div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 bg-img clip-home h-100"></div>
                </div>
            </div>
        </div>




    );
}

export default Login;