import React from 'react';
import {HashRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';
import Shoping from './components/views/shoping/Shoping';
import Productos from './components/views/productos/Productos';
import Odin from './components/views/odin/Odin';
import Esquemas from './components/views/esquemas/Esquemas';
import Proyectos from './components/views/proyectos/Proyectos';
import Clones from './components/views/clones/Clones';



function App() {
  return (
    <MyContextProvider>
    <Router>
     <Inicio/>

      <Routes>
      <Route history={history}></Route>
      <Route path="/Shoping" element = {<Shoping/>}></Route>
      <Route path="/Odin" element = {<Odin />}></Route>
      <Route path="/Productos" element = {<Productos />}></Route>
      <Route path="/Esquemas" element = {<Esquemas />}></Route>
      <Route path="/Proyectos" element = {<Proyectos />}></Route>
      <Route path="/Clones" element = {<Clones />}></Route> 
    
      <Route path="/" element={<Navigate replace to="/Shoping" />}></Route>
   
      </Routes>

 </Router>
 </MyContextProvider>
  );
}

export default App;
