import React, {useContext, useState, useEffect} from 'react';
import {MyContext} from '../../login/MyContext';
import axios from 'axios';

function Proyectos() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [modulo, setModulo] = useState('');
    const [lista, setLista] =  useState([]);
    const [imagen2, setImagen2] = useState(null);
    const [bandera, setBandera] = useState(true);
    const [id, setId] = useState(''); 


    useEffect(() => {
      getProductos();
    },[])


    async function getProductos(){
      const res = await axios.get('https://ai-od.com/proyectos/');
      setLista(res.data) 
      console.log(res.data)
  } 



    async function addProducto(e) {
      // e.preventDefault();
       let fd = new FormData() 
       fd.append("imagen2", imagen2)
       const res = await axios.post('https://ai-od.com/proyectos/', fd);
       console.log(res.data)
       getProductos();
    
      } 
   
     
     async function UpdateProducto(e) {
         const obj = {id};
         const res = await axios.put('https://ai-od.com/proyectos/', obj);
         console.log(res.data)
         getProductos();
     
     }
     
     function addUpdate(e) {
         e.preventDefault(); 
         bandera? addProducto():UpdateProducto();
    
     }


     async function getProducto(id){
      const res = await axios.get('https://ai-od.com/proyectos/?id='+id);
      setId(res.data.id);
      setModulo(res.data.modulo);
      
      setBandera(false)
  } 

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">

 

        
          <div className="col-md-12">

          <div className="callout callout-info">

         

<div class="card card-warning">
<div class="card-header">
<h3 class="card-title">PROYECTOS ACEPTADOS</h3>
</div>


<form>


<div class="card-body">



<div class="col-sm-6">

<div class="form-group">
<label>Proyectos</label>
<select class="form-control">
{/*<option>maxpain.ai-od.com</option>*/}
<option>magialatina.es</option>
</select>
</div>
</div>


{/*<h5> Inicio de proyecto - 3 de mayo 2022 - pagina referencia www.tecnipin.com</h5> */}
<h5> Inicio de proyecto - 15 de noviembre 2024 </h5>
<hr />
<div class="form-group">
{/*<label for="exampleInputEmail1">Modulo 1:</label><br />
<mark style={{ background: "#39DF08"}}>•	Que se tenga parte inferior del logo  teléfono de contacto 625825342 correo electrónico maxpaint@maxpain.ai-od.com <br />
•	Que tenga encabezado el logo <br />
•	Que tenga menú en la parte superior con botones que diga quienes somos <br />
•	Que tenga  botón servicios, botón trabajos realizados, contacto <br />
•	Que tenga botón de enlace a Instagram, facebook <br />
•	Que tenga botón de correo electrónico maxpaint@maxpain.ai-od.com</mark> <br />

    </div>*/}


<label for="exampleInputEmail1">Modulo 1:</label><br />
{/*<mark style={{ background: "#68FC39"}}>•	Creación de estructura pagina web <br /> */}
<mark style={{ background: "#68FC39"}}>1. El acceso portal.magialatina.es <br/>
1.1 creación de plantilla administrador optimizada <br/>
1.2 Creación de acceso para todos los usuarios<br/>
1.3 perfiles pra todos los usuarios <br/>
2. Usuario administrador global: adminlatam, Clave: 1236latam <br/>
3. Tipos de usuarios : Administradores: Son los encargados de administrar toda la plataforma<br/>
3.1. Clientes: Son los que compran en la tienda <br/>
3.2 mayoristas: son usuarios de compra, pero con el precio b, que seria precios especiales.
</mark> <br />

    </div>

{/*<button type="submit" class="btn btn-primary" disabled>VER AVANCE</button> el 27 de noviembre del 2024 9:00 pm <br/><br/> */}
<a  class="btn btn-success" href="https://portal.magialatina.es/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 27 de noviembre del 2024 9:00 pm
 

 <br/>
 <br/>
 <label for="exampleInputEmail1">Modulo 2:</label><br />
<mark >	1.	Conversión de usuarios: para convertir un usuario cliente a usuario mayorista el administrador solo deberá buscar el cliente registrado y cambiarle el check donde dice cliente a mayorista. <br />
4.	Administración clientes: Los clientes podrán acceder al portal con sus respectivas claves para ver el historial de compras, reclamos y/o estado de envió de sus productos <br />
5.	Soporte: desde la administración para clientes se podrá generar tickets por parte del cliente o mayorista para solucionar cualquier inquietud, cada ticket tendrá su propio id único donde le llagará a un módulo de tickets al administrador con fecha, asunto, remitente y respuesta.</mark> <br />
<br/>
<button type="submit" class="btn btn-primary" disabled>VER AVANCE</button> el 10 de diciembre del 2024 9:00 pm <br/><br/> 
{/*<div class="form-group">
<label for="exampleInputFile">Subir actualización</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'
onChange={(e) => setImagen2(e.target.files[0])}
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>


</div>
</div>*/}


{/*<button type="submit" class="btn btn-primary" disabled>ACTUALIZAR</button> */}
{/*<button  className="btn btn-primary" 
               onClick={(e) => addUpdate(e)} >
                 {bandera?"ACTUALIZAR":"Edit"}
</button> */}



          

{/* <hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo 2:</label><br />
<mark style={{ background: "#68FC39"}}>•	Que tenga el botón de menú de servicios colocar la siguiente información titulo  pintura decorativa agregar foto 1 agregar texto pintura decorativa con los mejores acabados y trabajos garantizados,siguiente servicio  pintura de fachadas agregar foto 2, siguiente servicio mantenimiento industrial agregar foto 3 agregar texto se preparar superficies y pintado de estructuras metálicas, impermeabilización de canales, rack de tuberias, reabilitacion y pintado de fachadas este trabajo garantiza la conservación de las distintas áreas externas para su buen funcionamiento , siguiente servicio preparación de superficies Agregar foto 4 agregar texto una correcta y cuidadosa preparación de superficie, antes de la aplicación aumentará la durabilidad del sistema de pintado lo que se convierte en una reducción de costos de mantenimiento, siguiente servicio preparación y pintura de suelos agregar foto 5 agregar texto base de resinas epoxídicas, de poliuretano, acrílicas, de metacrilato o poliaspárticas, se componen normalmente por dos componentes que, al mezclarse, endurecen por reacción química en un tiempo breve. Siguiente servicio pintura y señalización de parking agregar foto 7 agregar texto Los suelos de resinas con poliasparticos ( también conocidos como poliurea en frío ) son materiales bicomponentes de aplicación en frío y rápido curado, incluso a temperaturas bajo cero. Su naturaleza química alifática les permite resistir la luz UV sin necesidad de protección adicional en aplicaciones al exterior. Su flexibilidad y propiedades mecánicas los hacen aptos para zonas de tráfico rodado.
Siguiente título de servicio principal reformas de pisos locales y oficinas agregar foto 8 agregar texto se realizan todo tipo de reformas para su piso, local u oficina, reformamos baños,cocinas,suelos,divisiones de paredes en Pladul 
Siguiente servicio mudanzas dentro y fuera de barcelona agregar imagen 9 


</mark> <br />

    </div> */}

{/*<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 31 de agosto del 2022 9:00 pm <br/><br/>*/}
{/*<a  class="btn btn-success" href="https://picassopinturas.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 7 de septiembre del 2022 9:00 pm 
 



          

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo 3:</label><br />
<mark style={{ background: "#68FC39"}}>•	Que al dar clit en cada imagen de los servicios lo transporte a la opción de contáctanos donde salga la opción de apuntar nombre y celular  que esa información sea enviada al correo electrónico picassopinturas@ia-od.com
• que en el menú opción contacto aparezca la imagen número 10 en el encabezado tenga una casilla que diga nombre contacto y observaciones y enviar cuando le den enviar sea esa información dirigida al correo picassopinturas@ia-od.com



</mark> <br />

    </div>

    <a  class="btn btn-success" href="https://picassopinturas.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 10 de septiembre del 2022 9:00 pm 
 
    <hr />


<div class="form-group">
<label for="exampleInputEmail1">Actualización extra:</label><br />
<mark style={{ background: "#68FC39"}}>•	Modificar tipo de letra de los números telefónicos de la página por un tamaño más grande y en negrilla para que sea más fácil su visibilidad <br/>
• Agregar texto en el menú de inicio que diga debajo de la imagen 30 Bienvenido pinturas Picasso somos una empresa con dedicación al servicio de pinturas decorativas e industrial <br/>
• Contamos con la mejor experiencia y profesionales a su servicio en letra de tamaño grande que tenga buena visibilidad <br/>
• modificar la información del menú quienes somos como empresa y reemplazarlo por el siguiente texto . En Picasso estamos especializados en pinturas industrial y decorativa. Nuestro catálogo de servicios es muy extenso gracias a nuestras múltiples especialidades como el color, la decoración, etc. Además, contamos con interioristas profesionales que estarán encantados de aconsejarte en todo lo que necesites. El cliente siempre es nuestra prioridad, así que nos gusta poder conocer cada detalle del proyecto que tiene en su mente para poder aportar toda nuestra calidad y que obtenga exactamente lo que esperaba de nosotros. Esta siempre ha sido uno de nuestros pilares. <br/>
</mark>

<label for="exampleInputEmail1">Puntos resueltos:</label><br />
<mark style={{ background: "#68FC39"}}>•	cambiar imagen del menú de inicio que esta al iniciar la pagina remplazar por imagen 30 </mark>  <br/>
<mark style={{ background: "#68FC39"}}>• agregar menú de inicio  debajo del texto la imagen 31 </mark> <br/>
<mark style={{ background: "#68FC39"}}>• agregar debajo de la imagen 31 en el menú de inicio toda la información e imágenes del menú quienes somos como empresa </mark> <br/>

<mark style={{ background: "#68FC39"}}>• Agregar en el menú de inicio debajo de la información de quienes somos como empresa toda la información e imágenes del menú de servicios </mark> <br/>
<mark style={{ background: "#68FC39"}}>• agregar en el menú de inicio debajo de la información de servicios la información e imágenes del menú contacto </mark> <br/>


 <br />

    </div>

    <a  class="btn btn-success" href="https://picassopinturas.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 10 de septiembre del 2022 9:00 pm <br/> <br/>

    <a  class="btn btn-success" href="https://picassopinturas.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}> AVANCE FINALIZADO</a> el 24 de septiembre del 2022 9:00 pm <br/>
 
<div class="form-group">
<label for="exampleInputFile">Subir actualización</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'
onChange={(e) => setImagen2(e.target.files[0])}
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>


</div>
</div>

*/}
{/*<button type="submit" class="btn btn-primary" disabled>ACTUALIZAR</button> */}
{/*<button  className="btn btn-primary" 
               onClick={(e) => addUpdate(e)} >
                 {bandera?"ACTUALIZAR":"Edit"}
</button> */}
 




          

{/*<hr />*/}


{/*
<div class="form-group">
<label for="exampleInputEmail1">Modulo 2:</label><br />
<mark style={{ background: "#39DF08"}}>•	Que tenga boton de whatsapp que pregunte ¿ QUE NECESITA ?con numero +34625825342 <br />
•	Nombre del logo “milogomaxpaint” <br />
•	Que tenga botones  que diga contacta con nosotros y puedan llenar dato como nombre , correo de contacto y numero de contacto y descripción de trabajo a cotizar <br />
•	Que tenga parte inferior de el logo  imágenes con la siguiente información expertos en pintados de parkings de rotación la imagen sea de parkins , expertos en pulido litio adjuntar foto de suelo pintado , expertos en protección anticorrosiva adjunte imagen de tubos , expertos en pintura decorativa  <br />
<br/>
•	Modificar la información de contacto de el encabezado y de la totalidad de la página por teléfono de contacto 625825342-665128854<br />
•	Correo electrónico maxpaint03@gmail.com <br />
• Dirección Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
•	Modificar de los  botones de servicios , trabajos realizados, blog y contacto  la palabra tecnipin por palabra maxpaint <br />
•	Información de contacto por número 625825342-665128854 <br />
• Correo electrónico  maxpaint03@gmail.com <br />
• dirección Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
• Tanto como para el encabezado como para el final de la página <br />
• link de ubicación google https://goo.gl/maps/UqURb4Xfp5chL3GA9<br />
• dirección de domicilio Carrer de Tortosa, 122, 08918 Badalona, Barcelona<br />
• Modificar color principal rojo por el color azul <br />
• modificar en botón de trabajos realizados en la información de contacta con nosotros  <br />
• Número de contacto 625825342 botón de enlace WhatsApp <br />
• Correo electrónico maxpaint03@gmail.com </mark><br />

  </div> */}

{/*<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 9 de noviembre del 2021 <br/><br/>*/}
{/*<a  class="btn btn-success" href="https://maxpain.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 13 de mayo del 2022 - 9:00 PM 


<br/><br/>*/}{/*
 <h6><b>Observaciones</b></h6>
  <p>hola "diego" los puntos para el avance ya estan completados, por favor en un documento de word enviar los puntos si desea cambiar algo de lo ya actualizado, y tambien enviar los siguientes puntos que se detectaron:  
  <br/><mark>
  •	facebook e instagram <br/> 
 </mark> <br/></p>
    */ }
  

              


{/*<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo 3:</label><br />
<mark style={{ background: "#39DF08"}}>• Al dar clic en los números telefónicos de la totalidad de la página  sean enviados a enlace de llamada del número principal 625825342 y si dan clic en el número de oficina 665128854 sean enviados a enlace de llamadas automáticamente cada número telefónico por aparte tanto como el principal y aparte el número de oficina. </mark>  <br />
<br/> 
<a  class="btn btn-success" href="https://maxpain.ai-od.com/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 15 de mayo del 2022 - 11:30 AM
</div>






<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo 4:</label><br />
<mark style={{ background: "#39DF08"}}>
•Modificar todas las imágenes de la página por imágenes similares de internet <br/>

•Modificar información de quienes somos del menú inicial y del menú de quienes somos y reemplazarla por el siguiente texto En Maxpaint estamos especializados en pinturas industrial y decorativa.. Nuestro catálogo de servicios es muy extenso gracias a nuestras múltiples especialidades como el color, la decoración, etc. Además, contamos con interioristas profesionales que estarán encantados de aconsejarte en todo lo que necesites.

El cliente siempre es nuestra prioridad, así que nos gusta poder conocer cada detalle del proyecto que tiene en su mente para poder aportar toda nuestra calidad y que obtenga exactamente lo que esperaba de nosotros. Esta siempre ha sido uno de nuestros pilares
<br />
• quitar botón que dice saber más <br/>
• quite la información que dice algunos de nuestros clientes quitar todos los logos solo dejar el logo de seat<br/>
• quitar información de algunos de nuestros clientes quitar todos los logos <br/>
• quitar de la parte de debajo de la totalidad de la página la parte que dice certificados quitar logo de tuv Austria <br/>
• en la parte de contacto que dice nombre email y escribe su mensaje  esa información que escriban ay  al darle  enviada  los datos sean enviados al correo maxpaint03@gmail.com <br/>
• quitar del menú la opción blog <br/> </mark>




<br/>

<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> 24 a 48 horas - 22 de mayo del 2022 - 7:02 PM

<br/>
<b>obervaciones:</b> Hola "diego" los formularios de contacto  se restructuraron para funcionaran por que en "www.tecnipin.com" trabajan una api de terceros, por lo tanto se <b>incremento 48 horas mas de trabajo </b>

<br/>

</div>



           




<hr />

<div class="form-group">
<label for="exampleInputEmail1">Modulo 5:</label><br /> */}
{/*<mark style={{ background: "orange"}}>
<b>Observaciones 2</b> <br/>

Hola "diego", no se detectaron las imagenes, suba las imagenes en un archivo comprimido en .rar o .zip donde cada imagen lleve el nombre correspondiente como hizo referencia.
  </mark>*/}
{/*<mark style={{ background: "#39DF08"}}>

• quitar del menú el botón quienes somos <br />
• eliminar del inicio la totalidad de las imágenes de oficinas y de servicios <br />
• menú de contacto eliminar imagen donde dice contacto necesitas más información contacta con nosotros y remplazar por imagen de nombre contacto<br />
• menú de inicio retirar imagen y frase que dice expertos en pintado de parkings de rotación y remplazar por 
 Frase pintura decorativa agregar imagen piso 1 sin marca de agua <br />
• Menú inicio retirar imagen y frase que dice expertos en pulido de litio y remplazar por frase pintura de fachada y agregar imagen fachada sin marca de agua <br />
• Menú de inicio retirar imagen y frase que dice expertos en protección anticorrosiva y remplazar por frase pintura anticorrosiva y agregar imagen estructuras metálicas sin marca de agua<br /> 
• Menú inicio retirar imagen y frase expertos en protección pasiva contraincendios y remplazar por pintura de suelos y agregar imagen suelos 1 sin marca de agua <br />
• Menú inicio retirar imagen y frase expertos en impermeabilización y remplazar por frase pintura de parkings y señalización agregar imagen 18 sin marca de agua <br />
• Menú inicio retirar imagen y frase expertos en pintura decorativa y remplazar por frase pavimentos Multicapa agregar imagen  pavimentos sin marca de agua <br />
• menú de inicio remplazar información e imágenes de los servicios por 
Servicios<br />
• Pintura decorativa
En Maxpaint ofrecemos una excelente carta de colores brindando a nuestros clientes lograr embellecer interiores y exteriores personalizando lugares con los mejores acabados 
Agregar imagen piso 1   
<br/>
• Pintura de suelos 
La pintura para suelos cubre y protege una amplia diversidad de suelos: de hormigón, cemento, cerámica, suelos de madera o laminados, piedra… Además de mejorar su estética, permite hacer resistentes los suelos al tránsito intenso, al ser válido tanto en interiores como exteriores.
Agregar imagen  suelos 1 
<br/>
• Pintura de estructuras industriales
Conserva y restablece todo tipo de estructuras metálicas de depósitos y fabricas con protección anticorrosiva 
Agregar imagen estructuras 1 
<br/>

• botón de inicio ver mas servicios al dar clic transportar a el menú de servicios <br/>
• modificar información de botón del menú servicios por la siguiente información <br/>
Título servicios
 agregar imagen servicios sin marca de agua  y quitar frase descubre lo que Maxpaint puede hacer por si empresa 
Pintura decorativa 
En Maxpaint ofrecemos una excelente carta de colores brindando a nuestros clientes lograr embellecer interiores y exteriores personalizando lugares con los mejores acabados 
Agregar imagen piso 1  
<br/>
Pintura de fachadas 
Se realizan pinturas de cualquier tipo de fachadas con pintura plástica o esmaltadas 
Con los mejores acabados 
Agregar imagen fachada 
<br/>
Pintura de suelos 
La pintura para suelos cubre y protege una amplia diversidad de suelos: de hormigón, cemento, cerámica, suelos de madera o laminados, piedra… Además de mejorar su estética, permite hacer resistentes los suelos al tránsito intenso, al ser válido tanto en interiores como exteriores.
Agregar imagen suelos 
<br/>
Pintura anticorrosiva 
Se pintan todo tipo de estructuras depósitos y fábricas con pintura anticorrosiva que conserve y mejore su aspecto y durabilidad de el elemento pintado 
Agregar imagen estructuras metálicas 
<br/>
Pintado de parkings 
Se realiza preparación de pavimentos pintados y señalización vial con los mejores materiales Para ara lograr su mayor duración 
Agregar imagen parkings
<br/>
Pavimentos 
Somos especialistas en autonivelantes,multicapas antiestáticos y ciclos de pintado con los mejores materiales del mercado 
Agregar imagen pavimentos 
<br/>
• modificar información en su totalidad  del botón menú trabajos realizados por la siguiente 
Título trabajos realizados 
Agregar imagen trabajos realizados 
Pintura decorativa 
Agregar imágenes 1,2
Pintura fachadas 
Agregar imágenes 5,6 
Pintura anticorrosiva
Agregar imágenes 9,10,

Pintura de suelos 
Agregar imágenes 13,14

Pintura parking 
Agregar imágenes 17,18
Señalización 
Agregar Imagen  21,22
Pintura estructuras industriales 
Agregar imágenes 32,33 

 <br/>• modificar de el botón del menú contacto la imagen del título y reemplazarla por imagen contacto y quitar la pregunta ¿ necesitas más información?  Y solo dejar la frase contacta aquí 

</mark>

<br/>
<br/>

<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> el 31 de mayo del 2022 a las 9:00 PM

{/*<a  class="btn btn-success" href="https://maxpaint.com.co/" target="_blank" style={{textDecoration:"none", backgroundColor:"#39DF08"}}>VER AVANCE</a> 24 a 48 horas - 22 de mayo del 2022 - 7:02 PM */}

{/*<b>obervaciones:</b> Hola "diego" los formularios de contacto  se restructuraron para funcionaran por que en "www.tecnipin.com" trabajan una api de terceros, por lo tanto se <b>incremento 48 horas mas de trabajo </b>*/}

{/*

</div> */}

{/*<div class="form-group">
<label for="exampleInputFile">Subir actualización</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'
onChange={(e) => setImagen2(e.target.files[0])}
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>


</div>
</div> 


<br/>

<div class="card card-warning">
<div class="card-header">
<h3 class="card-title ">ACTUALIZACIONES CREADAS</h3>
<div class="card-tools">
<ul class="pagination pagination-sm float-right">

</ul>
</div>
</div>

<div class="card-body p-0">
<table class="table">
<thead>

</thead>

{<tbody>

{ lista.map( proyectos  => (
             <div  key={proyectos.id}>
               <tr>
               <td></td>
               <th style={{width: "10px"}}>{proyectos.modulo}</th>
            */}
               {/*<td style={{width: "40px"}}> <b>Validando </b>  (de 2 a 8 horas) </td>*/}
             {/*  <td className='btn btn-warning'>en proceso</td>
               
               
              <td>{proyectos.fecha}</td> */}
             {/*  <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td> */}
            {/* <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td>
               </tr>
            
             </div>         
          ))}  

</tbody> } 

</table> 
</div>

</div>   */}






{/*<button type="submit" class="btn btn-primary" disabled>ACTUALIZAR</button> */}
{/*<button  className="btn btn-primary" 
               onClick={(e) => addUpdate(e)} >
                 {bandera?"ACTUALIZAR":"Edit"}
</button>  */}

{/*
<div class="form-group">
<label for="exampleInputEmail1">Modulo 2:</label>
<b>pagina web - Fecha de entrega el 25 de noviembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “cronograma” <br />
•	En el modulo “cronograma” contiene los siguientes submodulos: hora de entrada, hora de salida, tanques <br />
•	En “horas de entrada” que contenga los siguientes campos:  hora de ingreso, cedula de ingreso, motivo de ingreso. <br />
•	En “horas de salida” que contenga los siguientes campos: hora de salida, cedula de ingreso, motivo de ingreso <br />
•	En “tanques” que contenga los siguientes campos: numero de tanque , que me cargue el modulo de hora de salida los usuarios registrados para seleccionar uno.
 <br />

</div>

<button type="submit" class="btn btn-danger" disabled>CORREPCIÓN O ADICIÓN</button> el 25 de noviembre del 2021 <br/><br/>
•	Agregar logo "logomenu" centrado  en la parte superior de los submodulos del cronograma <br /> <br/>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 27 de noviembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 27 de noviembre del 2021

<hr />


<div class="form-group">
<label for="exampleInputEmail1">Modulo 3:</label>
<b>pagina web - Fecha de entrega el 2 de diciembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “trabajador del mes” <br/>
•	En el modulo “trabajador del mes” contiene los siguientes submodulos: estadística, trabajador del mes.<br/>
•	En “estadística” que cargue todos los trabajadores del mes con los siguientes campos:  fecha de trabajador del mes, nombre trabajador del mes, cedula trabajador del mes.<br/>
•	En “trabajador del mes” que contenga los siguientes campos: que me cargue los usuarios para seleccionarlos del modulo de operarios.<br/>
 <br />

</div>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 2 de diciembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 2 de diciembre del 2021

<hr />




<div class="form-group">
<label for="exampleInputEmail1">Modulo 4:</label>
<b>pagina web - Fecha de entrega el 13 de diciembre a las 7:00 pm / 2021</b> <br />
•	Creación de modulo “mis tareas” <br/>
•	En el modulo “mistareas” contiene los siguientes campos: tareas, cargar los usuarios del modulo de operarios y que se les pueda asignar una tarea.<br/>

</div>

<button type="submit" class="btn btn-primary" disabled>ACEPTADO</button> el 13 de diciembre del 2021 <br/><br/>
<button type="submit" class="btn btn-warning" disabled>VER AVANCE</button> el 13 de diciembre del 2021

<hr />

<p>Clic - Página 2 </p>

*/}
</div>



<div class="card-footer">

{/*<div class="col-md-3 col-sm-6 col-12">
<div class="info-box bg-gradient-success">
<span class="info-box-icon"><i class="far fa-thumbs-up"></i></span>
<div class="info-box-content">
<span class="info-box-text">Proyecto</span>
<span class="info-box-number">48 días de trabajo</span>
<div class="progress">
<div class="progress-bar" style={{width: "100%"}}></div>
</div>
<span class="progress-description">
100% Finalizado


</span>

</div>
<button type="submit" class="btn btn-primary">VER PROYECTO FINALIZADO</button>

</div>

</div> */}

</div>


</form>
</div>
             


          
            </div>

            
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Proyectos
