import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'

function Odin() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
      <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<h3>8795<sup style={{fontSize: "20px"}}>Horas</sup></h3>
<p>Horas de entrenamiento</p>
</div>
<div class="icon">
<i class="fas fa-cog fa-spin fa-2x" style={{color:"#FAC37E"}}></i>
</div>
<a href="#" class="small-box-footer">ODIN IA <i class="fas fa-arrow-circle-up"></i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-warning">
<div class="inner">
<h3>4<sup style={{fontSize: "20px"}}>Programadores</sup></h3>
<p>Trabajadores equivalentes</p>
</div>
<div class="icon">
<i class="fa fa-users fa-2x" style={{color:"white"}}></i>
</div>
<a href="#" class="small-box-footer">ODIN IA <i class="fas fa-arrow-circle-up"></i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-success">
<div class="inner">
<h3>24 /<sup style={{fontSize: "20px"}}> 7</sup></h3>
<p>Días de trabajo</p>
</div>
<div class="icon">
<i class="fa fa-battery-three-quarters fa-2x" style={{color:"white"}}></i>
</div>
<a href="#" class="small-box-footer">ODIN IA <i class="fas fa-arrow-circle-up"></i></a>
</div>

</div>



        
          <div className="col-md-12">

          <div className="callout callout-info">
              <h5><i className="fa fa-code"></i> TECNOLIGÍAS APRENDIDAS</h5>
              <b>Backend y/o Frontend </b><br />
              Reactjs  <br />
              React-Native  <br />
              Html5 <br />
              bootstrap<br/>
              Php <br />
              Node <br />
              Mysql <br />
              Firebase <br />
             


          
            </div>

            
       
          </div>

         
        </div> 
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Odin
