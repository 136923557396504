import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'

import Login from '../../login/Login'

import Header from '../../layout/Header';
import Menu from '../../layout/Menu';




function Inicio() {

    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    
    if(isAuth)
    {
    return (
        <div>
            
           <Header></Header>
           <Menu></Menu>
            
            
           
            
        </div>
    )
}

else if(showLogin){
    return <Login/>;
}

}

export default Inicio