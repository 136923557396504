import React, {useContext} from 'react'
import {MyContext} from '../../login/MyContext'

function Shoping() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
      <div className="row" > 

        <div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h4>PACK 5 DOMINIOS</h4></center>
<hr/>
<p>3 dominios .com <br/>
2 dominios .com.co</p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$175.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>


<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h4>PACK 8 DOMINIOS</h4></center>
<hr/>
<p>4 dominios .com <br/>
4 dominios .com.co</p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$280.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>



<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h4>PACK 10 DOMINIOS</h4></center>
<hr/>
<p>6 dominios .com <br/>
4 dominios .com.co</p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$350.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>




<div class="col-lg-3 col-12">

<div class="small-box bg-info">
<div class="inner">
<center><h4>PACK 20 DOMINIOS</h4></center>
<hr/>
<p>15 dominios .com <br/>
5 dominios .com.co</p>
</div>
<div >

</div>
<a href="#" class="small-box-footer">
$600.000 COP / <i class="fas fa-arrow-circle-up"></i>
<i class="fa fa-shopping-cart " style={{color:"#FAC37E"}}> </i></a>
</div>

</div>


        
      <div className="col-md-12">

          <div className="callout callout-info">
              <h5><i className="fa fa-shopping-cart"></i> Shoping</h5>
              
              <p>• Los dominios de cada paquete tienen una vigencia de un año despues de la activación. <br/>
              • Cada dominio se activaría cuando el cliente quiera utilizarlo, de resto estarán archivados. <br/>
              • Todos los dominios vienen con certificado SSL.   </p>
             
            
             


          
            </div>

            
       
    </div> 

         
        </div> 
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Shoping
