import React, {useContext, useState, useEffect} from 'react';
import {MyContext} from '../../login/MyContext';
import axios from 'axios';

function Clones() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    const [modulo, setModulo] = useState('');
    const [lista, setLista] =  useState([]);
    const [imagen2, setImagen2] = useState(null);
    const [bandera, setBandera] = useState(true);
    const [id, setId] = useState(''); 
    const [fecha, setFecha] = useState('');
    const [logos, setLogos] = useState(null);

    const [nombre, setNombre] = useState('maxpaint.com.co - 15 de mayo del 2022');
    const [nombre2, setNombre2] = useState('maxpaint.es');
    const [nombre3, setNombre3] = useState('');


    useEffect(() => {
      getProductos();
    },[])


    async function getProductos(){
      const res = await axios.get('https://ai-od.com/clones/');
      setLista(res.data) 
      console.log(res.data)
  } 



    async function addProducto(e) {
      // e.preventDefault();
       let fd = new FormData() 
       fd.append("imagen2", imagen2)
       fd.append("nombre", nombre)
       fd.append("nombre2", nombre2)
       fd.append("nombre3", nombre3)
       const res = await axios.post('https://ai-od.com/clones/', fd);
       console.log(res.data)
       getProductos();
    
      } 
   
     
     async function UpdateProducto(e) {
         const obj = {id};
         const res = await axios.put('https://ai-od.com/clones/', obj);
         console.log(res.data)
         getProductos();
     
     }
     
     function addUpdate(e) {
         e.preventDefault(); 
         bandera? addProducto():UpdateProducto();
         limpiarEstado();
     }


     async function getProducto(id){
      const res = await axios.get('https://ai-od.com/esquema/?id='+id);
      setId(res.data.id);
      setNombre(res.data.nombre);
      setNombre2(res.data.nombre2);
      setNombre3(res.data.nombre3);
      setFecha(res.data.fecha);

      setFecha(res.data.imagen2);
      setFecha(res.data.logos);
      setBandera(false)
  } 
  
  function limpiarEstado(){
    setId('');
    setNombre('');
    setNombre2('');
    setNombre3('');
    setBandera(true);

    setImagen2();
    setLogos();
  }


    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">

 

        
          <div className="col-md-12">

          <div className="callout callout-info">

<div class="card card-success">
<div class="card-header">
<h3 class="card-title">CLONES</h3>
</div>


<form>


<div class="card-body">


{/*
<div class="form-group">
<label>Proyectos disponibles</label>
<input type="text" placeholder="maxpain.ai-od.com - 15 de mayo del 2022" className="form-control" disabled
                 onChange={(e) => setNombre(e.target.value)}
                 value={nombre}/>

    </div> */}


<div class="form-group">
<label>Proyectos disponibles</label>
<input type="text" placeholder="maxpaint.com.co - 15 de mayo del 2022" className="form-control" disabled
                 onChange={(e) => setNombre(e.target.value)}
                 value={nombre}/>

</div>


<div class="form-group">
<label for="exampleInputEmail1">Nombre del dominio para el Clon</label>
<input type="text" placeholder="Selecciona" className="form-control" disabled
                 onChange={(e) => setNombre2(e.target.value)}
                 value={nombre2}/>
</div>

<div class="form-group">
<label for="exampleInputEmail1">Nombre para el Clon</label>
<input type="text" placeholder="EMPRESA SAS" className="form-control" 
                 onChange={(e) => setNombre3(e.target.value)}
                 value={nombre3}/>
</div>

<div class="form-group">
<label for="exampleInputFile">Subir logo</label>
<div class="input-group">
<div class="custom-file">
<input type="file" class="form-control-file" className='btn btn-warning'
onChange={(e) => setImagen2(e.target.files[0])}
/>
<label class="custom-file-label" for="exampleInputFile">Clic</label>
</div>
<div class="input-group-append">
<span class="input-group-text">Subir</span>
</div>
</div>
</div>

</div>

<div class="card-footer">
<button  className="btn btn-primary" 
               onClick={(e) => addUpdate(e)}  >
                 {bandera?"CREAR CLON":"Edit"}
                </button> 

                <h6>Todos los clones se demoran en aparecer entre 1 a 2 días</h6>
                


</div>



<div class="card card-warning">
<div class="card-header">
<h3 class="card-title ">CLONES EN PROCESO</h3>
<div class="card-tools">
<ul class="pagination pagination-sm float-right">

</ul>
</div>
</div>

<div class="card-body p-0">
<table class="table">
<thead>

</thead>

{<tbody>

{ lista.map( clones  => (
             <div  key={clones.id}>
               <tr>
               <td></td>
               <th style={{width: "10px"}}>{clones.modulo}</th>
            
               {/*<td style={{width: "40px"}}> <b>Validando </b>  (de 2 a 8 horas) </td>*/}
               <td className='btn btn-warning'>en proceso</td>
               
               
              <td>{clones.fecha}</td>
             {/*  <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td> */}
             <td><span class="badge bg-primary"> <b>IA VERIFICANDO ACTUALIZACION</b></span></td>
             
               </tr>
            
             </div>         
          ))}  

</tbody> } 

</table> 
</div>

</div> 





<div class="card card-success">
<div class="card-header">
<h3 class="card-title ">CLONES CREADOS</h3>
{/*
<div class="card-tools">
<ul class="pagination pagination-sm float-right">
<li class="page-item"><a class="page-link" href="#">«</a></li>
<li class="page-item"><a class="page-link" href="#">1</a></li>
<li class="page-item"><a class="page-link" href="#">»</a></li>
</ul>
</div> */}

</div>

<div class="card-body p-0">
<table class="table">
<thead>
<tr>
<th style={{width: "10px"}}>#</th>
<th>Nombre del clon</th>
<th>Link de acceso</th>
<th>Fecha de creación</th>
<th style={{width: "40px"}}>Finalización</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>maxpaint</td>
<td><a href='https://maxpaint.com.co/' target="_blank">maxpaint.com.co</a></td>
<td>2022-05-16 2:48:45
</td>
<td><span class="badge bg-success">SI</span></td>
</tr>


</tbody>
</table>
</div>

</div>

</form>
</div>
             


          
            </div>

            
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    
    }

export default Clones
